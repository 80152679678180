<template>
  <div :class="classInput">
    <div class="form-group" :class="{'is-invalid': isInvalid, 'input-group-disabled': isDisabled}">
      <label class="label" :class="{'required': isRequired}">{{ label }}</label>
      <input
        :value="value"
        type="email"
        class="form-control"
        :class="{'is-invalid': isInvalid || (emailInvalid && value)}"
        @blur="() => validate()"
        @input="inputHandle($event.target.value)"
        :ref="'refinput'+id"
      >
      <div
        v-if="isRequired || (emailInvalid && value)"
        class="invalid-feedback"
      >
        <span v-if="isInvalid">Campo obrigatório.</span><br />
        <span v-if="emailInvalid">E-mail inválido.</span>
      </div>
    </div>
  </div>
</template>
<script>
import { validEmail } from './validate/validEmail'

export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    typeInput: {
      type: String
    },
    value: {
      type: String
    },
    classInput: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      emailInvalid: false,
      isInvalid: false,
      hasTouched: false
    }
  },
  methods: {
    inputHandle (value) {
      this.hasTouched = true
      this.$emit('input', value)
    },
    validate () {
      this.emailInvalid = !validEmail(this.value)
      this.isInvalid = ((this.value && !validEmail(this.value)) || this.isRequired) || (this.isRequired && this.hasTouched && this.value === null) || (this.isRequired && !this.value)
    },
    isValid () {
      this.validate()
      return !this.isInvalid
    },
    clean () {
      this.isInvalid = false
      this.hasTouched = false
    }
  }
}
</script>
